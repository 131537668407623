<template>
  <div class="container mx-auto">
    <h1 class="text-3xl font-light text-sky-blue mb-8 text-center md:text-left">Welcome, I am Maxime Denis</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div class="flex flex-col lg:col-span-2 justify-center p-6 text-justify font-light">
        <p class="mb-4">
          As a Software and Data Engineer at <a
            href="https://www.semetis.com/"
            class="cursor-pointer text-sky-blue underline hover:text-night-blue"
            target="_blank" rel="noopener noreferrer"
        >Semetis</a>, I develop custom software to fulfill both our team's and clients' needs.
          My journey started at the Royal Military Academy of Brussels in 2014, where I began my studies as an officer-pilot of the Air Component for the Belgian Defence.
          However, a year later, I redirected myself towards Computer Engineering at <a
            href="https://www.ecam.be/"
            class="cursor-pointer text-sky-blue underline hover:text-night-blue"
            target="_blank" rel="noopener noreferrer"
        >ECAM Brussels</a>, where I graduated in 2021.
        </p>
        <p class="mb-4">
          My passion for development has led me to put in extra hours for side projects, helping friends develop their businesses or for my own purposes.
          In early 2023, I decided to become a part-time freelancer to further explore my passion.
          In addition to my professional life, I am an avid sports enthusiast, playing hockey and serving as an international referee.
          I also enjoy rounds of golf or long bike rides during my free time.
        </p>
      </div>
      <div class="flex justify-center items-center rounded-lg">
        <img alt="Maxime Denis" class="w-72 rounded-lg custom-shadow-nohover" src="../assets/images/max.jpg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>

<style>
</style>
