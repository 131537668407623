<template>
  <div class="text-center w-full py-3 px-4 text-gray-500 text-sm">
    <div class="md:flex justify-between items-center">
      <div class="mb-4 md:mb-0">Designed and built with <span class="text-red-500">♥</span> by <span class="text-gray-600">Maxime Denis</span></div>

      <div class="flex justify-center">
        <a :href="linkedin" target="_blank" class="text-gray-500 hover:text-blue-500"><i class="fab fa-linkedin-in fa-lg"></i></a>
        <a :href="instagram" target="_blank" class="ml-2 text-gray-500 hover:text-pink-500"><i class="fab fa-instagram fa-lg"></i></a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";

const linkedin= ref('https://www.linkedin.com/in/maximedenis14/')
const instagram= ref('https://instagram.com/mdenis4?igshid=NTc4MTIwNjQ2YQ==')
</script>

<style scoped>

</style>