<template>
  <div class="flex flex-col w-full h-screen bg-gray">
    <HeaderComp class=""></HeaderComp>
    <div class="flex-1 overflow-y-auto" ref="scrollContainer">
      <router-view class="py-16 px-8 md:px-32 w-full"/>
    </div>
    <FooterComp class="bg-white"></FooterComp>
  </div>
</template>


<script>
import HeaderComp from "@/components/HeaderComp";
import FooterComp from "@/components/FooterComp";
export default {
  components: {FooterComp, HeaderComp},
  beforeCreate() {
    document.title = "Maxime Denis"
  },
  methods: {
    scrollToTop() {
      this.$refs.scrollContainer.scrollTop = 0;
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.scrollToTop();
      next();
    });
  },
}
</script>