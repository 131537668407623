import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/work',
    name: 'work',
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkView.vue')
  },
  {
    path: '/education',
    name: 'education',
    component: () => import(/* webpackChunkName: "about" */ '../views/EducationView.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue')
  },
  {
    path: '/wave',
    name: 'wave',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/WaveView.vue')
  },
  {
    path: '/kts',
    name: 'kts',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/KTSView.vue')
  },
  {
    path: '/ombrage',
    name: 'ombrage',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/OmbrageView.vue')
  },
  {
    path: '/avote',
    name: 'avote',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/AvoteView.vue')
  },
  {
    path: '/apache',
    name: 'apache',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/ApacheView.vue')
  },
  {
    path: '/trm',
    name: 'trm',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/TRMView.vue')
  },
  {
    path: '/ambrose',
    name: 'ambrose',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/AmbroseView.vue')
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/ReportingView.vue')
  },
  {
    path: '/hwb',
    name: 'hwb',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/HWBView.vue')
  },
  {
    path: '/destin',
    name: 'destin',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects/DestinView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0) // scroll to the top of the page
  next() // proceed with the navigation
})

export default router
