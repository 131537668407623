<template>
  <div class="bg-white text-sky-blue w-full">
    <nav
        class="
        container
        px-6
        py-2
        mx-auto
        md:flex md:justify-between md:items-center
      "
    >
      <div class="flex items-center justify-between">
        <router-link
            to="/"
            class="
            text-xl
            font-bold
            text-sky-blue
            md:text-2xl
            hover:text-night-blue
          "
        ><img class="h-12 aspect-auto" :src="logo" alt="Logo">
        </router-link>
        <!-- Mobile menu button -->
        <div class="flex md:hidden">
          <button
              @click="toggleMenu"
              type="button"
              class="
              text-gray-400
              hover:text-sky-blue
              focus:outline-none focus:text-sky-blue
            "
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile menu -->
      <ul
          v-show="showMenu"
          class="
          flex-col
          justify-end
          w-full
          mt-8
          space-y-4
          md:hidden
        "
      >
        <li class="text-gray-400 hover:text-sky-blue w-full">
          <button class="w-full text-left" @click="goTo('home')">Home</button>
        </li>
        <li class="text-gray-400 hover:text-sky-blue w-full">
          <button class="w-full text-left" @click="goTo('work')">Career</button>
        </li>
        <li class="text-gray-400 hover:text-sky-blue w-full">
          <button class="w-full text-left" @click="goTo('education')">Education</button>
        </li>
<!--        <li class="text-gray-400 hover:text-sky-blue w-full">Other</li>-->
        <li class="text-gray-400 hover:text-sky-blue w-full">
          <button class="w-full text-left" @click="goTo('projects')">Projects</button>
        </li>
        <li class="text-gray-400 hover:text-sky-blue w-full">
          <button class="w-full text-left" @click="goTo('about')">About Me</button>
        </li>
      </ul>

      <!-- Desktop menu -->
      <ul
          class="
          flex-col
          justify-end
          hidden
          md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0
        "
      >
        <li class="text-gray-400 hover:text-sky-blue">
          <router-link to="/">Home</router-link>
        </li>
        <li class="text-gray-400 hover:text-sky-blue">
          <router-link to="/work">Career</router-link>
        </li>
        <li class="text-gray-400 hover:text-sky-blue">
          <router-link to="/education">Education</router-link>
        </li>
<!--        <li class="text-gray-400 hover:text-sky-blue">Other</li>-->
        <li class="text-gray-400 hover:text-sky-blue">
          <router-link to="/projects">Projects</router-link>
        </li>
        <li class="text-gray-400 hover:text-sky-blue">
          <router-link to="/about">About Me</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script setup>
import logo from '@/assets/images/logo.png';
import {ref} from "vue";
import router from "@/router";
const showMenu = ref(false)

function toggleMenu() {
  showMenu.value = !showMenu.value
}

function goTo(page) {
  router.push({name: page})
  showMenu.value = false
}
</script>

<style scoped>

</style>